<template>
    <section class="showcase" id="home">
        <div class="container">
            <div class="display flex-align">
                <div class="write-up">
                    <h1>Linkmeup</h1>
                    <h2>Everyday made better!</h2>
                    <!-- <p>
                        Linkmeup is a multifunctional activity management application, designed to make daily activities easier for you to manage and execute.
                    </p> -->
                    <div class="download flex-align">
                        <a target="_blank" href="https://play.google.com/store/games">
                            <img src="../../../assets/images/pri_play_dowload.png" alt="playstore-download" width="150">
                        </a>
                        <a target="_blank" href="https://www.apple.com/app-store/">
                            <img src="../../../assets/images/appstore-download.png" alt="playstore-download" width="130">
                        </a>
                    </div>
                </div>

                <div class="images">
                    <!-- <Carousel :settings='settings' :breakpoints='breakpoints' :wrapAround="true"  :autoplay="3000">
                        <Slide v-for="(value, index) in 8" :key="index">
                            <div class="carousel__item">
                                <img :src="require(`../../../assets/mocks/${value}.png`)" alt="mobile app">
                            </div>
                        </Slide> -->

                        <!-- <template #addons> -->
                        <!-- <Navigation /> -->
                        <!-- <Pagination /> -->
                        <!-- </template> -->
                    <!-- </Carousel> -->
                    <img src="@/assets/Primary.png" alt="">
                </div>

                <div class="download2 flex-align">
                    <a target="_blank" href="https://play.google.com/store/games">
                        <img src="../../../assets/images/pri_play_dowload.png" alt="playstore-download" width="200">
                    </a>
                    <a target="_blank" href="https://www.apple.com/app-store/">
                        <img src="../../../assets/images/appstore-download.png" alt="playstore-download" width="200">
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'Basic',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data: () => ({
    // images

    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
      transition: '1000'
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 600px and up
      600: {
        itemsToShow: 2.65,
        snapAlign: 'center',
      },
    },
  }),
});
</script>


<style scoped>
    .showcase {
        min-height: 95vh;
        /* background: linear-gradient(#093AC9, #5778dc); */
        background: url('../../../assets/images/primary.png');
        background-size: cover;
        color: #fff;
        padding: 3rem 0;
    }

    .download, .download2 {
        width: 300px;
    }

    .download > img {
        cursor: pointer;
    }

    .display > div {
        /* width: 50%; */
        flex: 1;
        overflow-x: hidden;
        /* border: 2px solid red; */
    }

    .display {
        padding: 3rem;
    }

    .images img {
        /* width: 210px; */
        width: 100%
    }

    .write-up h2, p {
        margin-bottom: 10px;
    }

    .write-up h1 {
        font-size: 6rem;
    }

    .write-up h2 {
        font-size: 2.4rem;
        width: 400px;
        margin-bottom: 20px;
    }

    .write-up P {
        margin-bottom: 40px;
        font-size: 14px;
    }

    .images {
        margin-left: 20px;
        padding: 20px;
    }

    .download2 {
        display: none;
    }

    .carousel__item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 500px;
        width: 100%;
        margin: 0 auto;
    }

    .carousel__slide > .carousel__item {
        transform: scale(1);
        opacity: 1;
        transition: 0.5s;
    }
    .carousel__slide--visible > .carousel__item {
        opacity: 1;
        transform: rotateY(0);
    }
    .carousel__slide--next > .carousel__item {
        transform: scale(0.9) translate(-10px);
    }
  
    .carousel__slide--prev > .carousel__item {
        transform: scale(0.9) translate(10px);
    }
    .carousel__slide--active > .carousel__item {
        transform: scale(1.2);
        z-index: 1;
    }

      @media(max-width: 900px) {
      .display > div {
          width: 100%;
          text-align: center;
      }

      .display p, .display h2 {
          width: 100%;
          padding: 0;
          margin-top: 20px;
      }

      .display p {
          text-align: center;
      }

      .display h2 {
          font-size: 1.5rem;
      }

      .display h1 {
          font-size: 4rem;
      }

      .display {
          flex-direction: column;
          padding: 3rem 2rem;
          /* border: 2px solid red; */
      }

      .images {
        margin-bottom: 2.5rem;
      }

      .download {
          display: none;
      }

      .download2 {
          display: flex;
          flex-direction: column;
      }
    }

    @media(max-width:769px){
        .showcase {
            padding-top: 5rem;
        }
    }
</style>