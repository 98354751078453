<template>
  <div class="api" id="api" data-aos="fade-up">
      <div class="container">
            <h1 >API Integration</h1>
            <p>Linkmeup API documents provides seamless integration for 3rd parties to integrate Linkmeup services directly into their platforms such as websites, software applications, devices and equipment. Find out how Linkmeup API supports your business.</p>
            <!-- <ul>
                <li>Linkmeup Individual API docs for businesses</li>
                <li>Linkmeup Security API docs for businesses</li>
                <li>Business that Linkmeup API docs support</li>
            </ul> -->
            <div class="boxes flex-align">
                <div class="box flex-center">
                    <div class="visual flex-center">
                        <i class="fa fa-book"></i>
                    </div>
                    <p>Linkmeup individual API docs for businessses</p>
                </div>
                <div class="box flex-center">
                    <div class="visual flex-center">
                        <i class="fa fa-book"></i>
                    </div>
                    <p>Linkmeup security API docs for businesses</p>
                </div>
                <div class="box ">
                    <div class="visual flex-center">
                        <i class="fa fa-book"></i>
                    </div>
                    <p>Businesses that Linkmeup API docs support</p>
                </div>
            </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.api {
    padding-top: 5rem;
}

h1, h2, h3, h4, h5 {
        color: #093AC9;
    }

     h1 {
        font-weight: 800;
        font-size: 2rem;
    }

    h1, p {
        text-align: center;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        width: 80%;
        margin: 0 auto;
        color: #464646;
        margin-bottom: 2rem;
    }

    .boxes {
        width: 80%;
        margin: 0 auto;
        margin-bottom: 10rem;
        font-size: 14px;
    }

    .boxes > div {
        padding: 20px;
        width: 30%;
        height: 200px;
        background-color: #fff;
        box-shadow: 0 0 6px 3px #eeeeee;
        border-radius: 20px;
        flex-direction: column;
    }

    .visual {
        height: 40px;
        width: 40px;
        background-color: #093AC9;
        color: #fff;
        margin: 0 auto;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    @media(max-width: 700px) {
        p {
            width: 95%;
            margin: 0 auto;
            padding: 10px;
            margin-bottom: 2.5rem;
        }

        .boxes {
            flex-direction: column;
        }

        .boxes > div {
            width: 300px;
            margin-bottom: 2rem;
        }
    }

</style>