<template>
  <div class="footer">
      <div class="container">
          <div class="top flex-align"  data-aos="fade-left">
            <div>
                <div class="logo flex-align" style="width: 100px">
                    <img src="../../../assets/images/pri_logo.png" alt="logo" width="30" style="margin-right: 7px">
                    <h2>Linkmeup</h2>
                </div>
                <div class="logo-link">
                    <p class="head">Linkmeup Office Address:</p>
                    <p class="cont">1835, 1007 N Orange St. 4th Floor , Wilmington, DE, New Castle, US, 19801.</p>
                </div>
                 <div class="logo-link">
                    <div class="flex"><p>Contact:</p>+1 (302) 440-3393</div>
                    <div class="flex"><p>Email:</p> support@linkmeup.us</div>
                </div>
            </div>

            <!-- <div>
                <div class="logo l2 flex-align" style="width: 100px; visibility: hidden">
                    <img src="../../../assets/images/pri_logo.png" alt="logo" width="30" style="margin-right: 7px">
                    <h2></h2>
                </div>
                <div class="logo-link">
                    <p class="head">Linkmeup Nigeria Address:</p>
                    <p class="cont">24 Olajide Olabanji Street Rahman Adeboyejo Estate Lekki Phase 1 opposite Lagoon Schools Lekki -Lagos</p>
                </div>
                 <div class="logo-link">
                    <div class="flex"><p>Contact:</p> +2349063153590</div>
                    <div class="flex"><p>Email:</p> support@linkmeup.us</div>
                </div>
            </div> -->
            
            <div>
                <h3>Our product</h3>
                <div class="links">
                    <router-link to="/">LMU Individual</router-link>
                    <router-link to="/security">LMU Security</router-link>
                    <a href="#api" v-smooth-scroll>LMU API</a>
                    <router-link to="/security#qr-code">LMU QR Code Kits</router-link>
                </div>
            </div>
            <div>
                <h3>Newsletter</h3>
                <div class="links flex-align news">
                    <input type="text" placeholder="Enter email...">
                    <button>Subscribe</button>
                </div>
                <div class="social flex-align">
                    <div class="flex-center">
                        <a href="https://www.facebook.com/Linkmeup_IAM-104540075644113/" target="_blank"><img src="../../../assets/img/facebook-logo.png" alt="facebook" width="25px"></a>
                    </div>

                    <div class="flex-center">
                        <a href="https://twitter.com/Linkmeup_IAM?t=MZkJqVzYos3s6JLTlISl1Q&s=09" target="_blank"><img src="../../../assets/img/twitter.png" alt="facebook" width="25px"></a>
                    </div>

                    <div class="flex-center">
                        <a href="https://instagram.com/linkmeup_iam?igshid=YmMyMTA2M2Y=" target="_blank"><img src="../../../assets/img/instagram.png" alt="facebook" width="25px"></a>
                    </div>

                    <div class="flex-center">
                        <a href="https://www.youtube.com/channel/UCCl4uzDEZRhIhYKx8hhrdvA" target="_blank"><img src="../../../assets/img/youtube.png" alt="facebook" width="25px"></a>
                    </div>
                    <div class="flex-center">
                       <a href="https://medium.com/@Linkmeup_IAM" target="_blank"><img src="../../../assets/img/medium.png" alt="facebook" width="25px"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="bottom">
            <div>
                &copy; {{ new Date().getFullYear() }} Linkmeup. All rights reserved. Linkmeup Limited
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .top {
        padding: 3rem 0;
        align-items: flex-start;
    }

    .top > div {
        flex: 1;
        padding-left: 20px;
    }

    .top > div h3, .top > div .logo {
        margin-bottom: 20px;
    }

    .bottom {
        text-align: center;
        font-size: 14px;
        color: #373737;
        /* font-weight: bold; */
        border-top: 1px solid #ccc;
        padding: 1.5rem 0;
    }

    .footer {
        padding: 2rem 0;
        background-color: #f3f3f3;
    }

    .links a {
        display: block;
        color: #373737;
        text-decoration: none;
        font-size: 14px;
        margin-bottom: 15px;
    }

    input {
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #ccc;
        margin-right: 5px;
    }

    button {
        background-color: #093AC9;
        color: #fff;
        padding: 10px;
        border-radius: 8px;
    }

    .logo h2 {
        color: #093AC9;
    }

    .logo-link p {
        font-size: 14px;
    }

    .logo-link {
        margin-bottom: 20px;
    }

    .logo-link > div {
        justify-content: flex-start;
        align-items: center;
    }

    .logo-link p:first-child {
        font-weight: 600;
    }
/* 
    .cont {
        width: 60%;
    } */

    .social {
        margin-top: 20px;
        justify-content: flex-start;
        width: 150px;
    }

    .social > div {
        height: 30px;
        width: 30px;
        text-align: center;
        /* background-color: #093AC9; */
        color: #fff;
        border-radius: 100%;
        margin-right: 3px;
    }

    .head {
        margin-bottom: 20px;
    }

    .news {
        justify-content: flex-start;
    }

    @media(max-width: 900px) {
        .top {
            flex-direction: column;
        }

        .top > div {
            width: 95%;
            margin-bottom: 2.5rem;
        }

        .l2 {
            display: none;
            visibility: visible;
        }
    }
</style>