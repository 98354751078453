<template>
  <div class="about" id="about">
      <div class="container">
        <div class="section"  data-aos="fade-up">
            <h1 >About us</h1>
            <p>Welcome to linkmeup, a technology company founded with the aim to make life easier for you, and solving your identity and access problems are just the beginning of our journey together.</p>
        </div>
        <div class="section">
            <h1  data-aos="fade-up">Vision</h1>
            <p  data-aos="fade-right">Our vision is to provide client centric services to make everyday life experiences easier, safer, and better than the previous for our customers.</p>
        </div>
        <div class="section"  data-aos="fade-up">
            <h1>Mission</h1>
            <p>Our mission is to make life easier for you while also increasing your privacy and general safety.</p>
        </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
h1, h2, h3, h4, h5 {
        color: #093AC9;
    }

    .section h1 {
        font-weight: 800;
        font-size: 2rem;
    }

    .section h1, p {
        text-align: center;
        margin-bottom: 20px;
    }

    .section p {
        font-size: 14px;
        width: 80%;
        margin: 0 auto;
        color: #464646;
        margin-bottom: 4rem;
    }

    @media(max-width: 768px) {
        .section h1, p {
            text-align: left;
            width: 80%;
            margin: 0 auto;
        }

        .section h1 {
            margin-bottom: 1rem;
        }
    }
</style>