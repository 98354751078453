<template>
  <div class="home">
    <div class="mobile-nav">
      <MobileNav :showNav="showNav" v-on:close-nav="showNav = false" :type="1"/>
    </div>
    <div class="content">
      <Header :type="1" v-on:open-mobile-nav="showNav = !showNav"/>
      <Showcase/>
      <Product/>
      <API/>
      <About/>
      <Feedback/>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import MobileNav from '../../components/MobileNav.vue'
import Showcase from './components/Showcase.vue'
import Product from './components/Product.vue'
import API from './components/API.vue'
import About from './components/About.vue'
import Feedback from './components/Feedback.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    MobileNav,
    Showcase,
    Product,
    API,
    About,
    Feedback,
    Footer
  },
  data(){
    return {
      showNav: false
    }
  },
  mounted(){
    // Talk to intialization

    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/629a8a5ab0d10b6f3e759ab0/1g4ls16ut';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  }
}
</script>

<style scoped>
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato', sans-serif;
  }
</style>