<template>
  <section class="product">
      <div class="container">
        <!-- <h1>Why Linkmeup?</h1> -->
        <h1>Your daily activity management app</h1>

        <div class="section section1 flex-align" data-aos="fade-left">
            <div class="image-holder" data-aos="fade-up">
                <img src="@/assets/1.gif" alt="app-photo" v-if="showActivityGif">
                <img src="@/assets/2.gif" alt="app-photo" v-else>
            </div>

            <div class="write-up"  data-aos="fade-left">
                <!-- We all know that as guests gaining access to places such as homes, estates,
                offices, events, and service destinations can be very difficult and time-consuming
                even after booking appointments for weeks or months ahead of the scheduled date. 
                And not to mention the rigorous security protocols you’d have to undergo after which 
                you’d be given a visitor's card, tag, pass, or access code before you are cleared for entry or exit as the case may be. -->
                <p>
                    Linkmeup is a multifunctional activity management application, designed to make daily activities easier for you to manage and execute.
                </p>
                <p>
                    The all in one solution aids your daily activities by increasing your productivity and efficiency, helping you navigate through your busy schedules with ease.
                </p>
            </div>
        </div>

        <div class="section2" data-aos="fade-up">
            <h2>Features</h2>
            <p>Linkmeup allows you to schedule and confirm meeting appointments, share contact information seamlessly with friends and clients, and gain quick entry to your destination without having to be in a queue to fill out lengthy visitor forms and undergo cumbersome security protocols.</p>
            <div class="info flex-align" data-aos="fade-left">
                <div class="write-up">
                    <h3>Personal Identity</h3>
                    <div>
                        Linkmeup is your new personal identity on your phone, it’s a good way for you to identify yourself everywhere you go without having to bring out your wallet for your physical identity card.
                    </div>
                </div>

                <div class="image-holder">
                    <img src="../../../assets/images/1.png" alt="personal">
                </div>
            </div>

            <div class="info flex-align" data-aos="fade-right">
                <div class="image-holder">
                    <img src="../../../assets/images/2.png" alt="personal">
                </div>

                <div class="write-up">
                    <h3>Speedy Entry to Destinations</h3>
                    <div>
                        With Linkmeup you’re never kept on the wait, no more queues and lengthy security protocols for you...so whether you are in a rush to step into a meeting, event, or pay a visit just scan your destination QR code and you are cleared for entry.
                    </div>
                </div>
            </div>

            <div class="info flex-align" data-aos="fade-left">
                <div class="write-up">
                    <h3>Contact Sharing</h3>
                    <div>
                        Linkmeup is the new cool way to exchange your contact information with friends; all they have to do is scan your Linkmeup QR and both contact information will be exchanged in a snap!
                    </div>
                </div>

                <div class="image-holder">
                    <img src="../../../assets/images/3.png" alt="personal">
                </div>
            </div>

            <div class="info flex-align" data-aos="fade-right">
                <div class="image-holder">
                    <img src="../../../assets/images/new4.png" alt="personal">
                </div>

                <div class="write-up">
                    <h3>Manage Appointments and Events</h3>
                    <div>
                        With just a single touch on your phone, you can easily manage your daily activities like viewing, scheduling, and confirming appointments while on the go. With Linkmeup you can send your next special invite ( such as a birthday party, marriage ceremony, official meeting, concert, event, or other special occasions) to your contact; and only those with confirmed invites will be allowed in.
                    </div>
                </div>
            </div>
        </div>
      </div>
  </section>
</template>

<script>
export default {
    data() {
        {
            return {
                showActivityGif: true
            }
        }
    },
    created(){
        setInterval(() => {
            this.showActivityGif = !this.showActivityGif
        }, 6000)
    }
}
</script>

<style scoped>
    /* Global styles for this component  */
    h1, h2, h3, h4, h5 {
        color: #093AC9;
    }

    .section1 img {
        width: 100%;
    }

    .section1 > div {
        flex: 1;
    }

    .section1 .image-holder {
        margin-right: 2.5rem;
    }

    .section1 {
        justify-content: center;
        margin-bottom: 4.5rem;
    }


    .section1 .write-up {
        width: 50%;
        font-size: 14px;
        padding: 1rem 3rem;
    }

    .section1 p {
        margin-bottom: 20px;
    }

    h1 {
        font-size: 3rem;
        text-align: center;
        margin-bottom: 4.5rem;
        padding-top: 3rem;
        width: 60%;
        margin: 0 auto;
    }

    .section2 h2 {
        font-weight: 800;
        font-size: 2rem;
        /* padding-top: 3rem; */
    }

    .section2 h2, .section2 p {
        text-align: center;
        margin-bottom: 20px;
    }

    .section2 p {
        font-size: 14px;
        width: 80%;
        margin: 0 auto;
        color: #464646;
        margin-bottom: 2rem;
    }

    .info > div {
        flex: 1;
        font-size: 14px;
    }

    .info h3 {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    .info .write-up {
        padding: 0 5rem;
    }

    .info img {
        width: 100%;
    }

    @media(max-width: 900px) {
        .section1 {
            flex-direction: column;
        }

        .section1 .write-up {
            width: 100%;
        }

        .section2 p {
            width: 97%;
            padding: 10px;
        }

        .info {
            flex-direction: column;
            margin-bottom: 3rem;
        }

        .info .image-holder {
            order: 1;
            text-align: center;
            margin-bottom: 3rem;
        }

        .info .image-holder img {
            width: 80%;
            margin: 0 auto;
        }

        .info .write-up {
            order: 2;
            padding: 10px;
            width: 100%;
        }

        h1 {
            width: 100%;
            font-size: 2rem;
        }
    }
</style>